import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaYoutube,
  FaMixcloud,
  FaRss,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa"
import { hideVisually } from "polished"
import imgLogo from "../../images/logo-horizontal.png"
import bgFooterCta from "../../images/bg-footer-cta-min.jpg"

const CompLayoutFooter = ({ currentPath }) => {
  
  return (
    <Footer>
      <div className="footer-cta">
        <p>
          機材を持っていない...
          <br />
          パソコンを持っていないと始めれない...
          <br />
          と思っている方も多いと思いますが心配無用！！
        </p>

        {currentPath === '/course/dtm' ? <p>
          <span>楽曲制作ソフトを使ったことがない方からリリースを目指す現役DJまで</span>
          <span>マンツーマンで様々なDTMのレッスンができます！</span>
        </p> : <p>
          TryHard DJ Academyでは、機材を買わずに、
          USBだけでDJのLessonが出来ます。
          <br />
          気軽にDJが始めれますよ！
        </p>}

        {currentPath === '/course/dtm' ? <p>
          <p className="cta-tagline">
            聴くから作る音楽へ！
            <br />
            ENJOY DTM LIFE !!
          </p>
        </p> : <p className="cta-tagline">
          「新しい音楽のカタチ」に触れてみてください
          <br />
          ENJOY DJ LIFE !!
        </p>}

        <Link to="/lesson">無料体験を受けてみる</Link>
      </div>
      <div className="sns">
        <div className="inner">
          <h3>LINKS</h3>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/TryHardDJAcademy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/TryHardDJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitterSquare />
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/tryharddjacademy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="http://blog.tryhard-dj-academy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaRss />
                <span>Blog</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCOY4OAAHLytoUWugxtSkGRw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
                <span>YouTube</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.mixcloud.com/Tryhard_Dj_Academy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaMixcloud />
                <span>MixCloud</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact">
        <div className="inner">
          <Link to="/">
            <img src={imgLogo} alt="TryHard DJ Academy" />
          </Link>
          <div className="phone">
            <div className="icon">
              <FaPhone />
            </div>
            <h3>
              TEL: 06-6226-8667
              <div className="phonehours">受付時間 12:00-22:00</div>
            </h3>
          </div>
          <div className="email">
            <div className="icon">
              <FaEnvelope />
            </div>
            <h3>Mail: info@tryhard.me</h3>
          </div>
        </div>
      </div>
      <div className="tagline">
        大阪心斎橋で学ぶ関西最大のDJ/DTMスクール | TryHard DJ Academy
      </div>
      <div className="copyright">
        © {new Date().getFullYear()} TryHard DJ Academy. All Rights Reserved.
      </div>
    </Footer>
  )
}

// Footer.propTypes = {}

export default CompLayoutFooter

const Footer = styled.footer`
  .footer-cta {
    padding: 2rem 0.5rem;
    background: url(${bgFooterCta}) no-repeat center/cover;
    text-align: center;

    p {
      font-family: sans-serif;
      margin: 0 0 1rem;

      span {
        display: block;

        @media (max-width: 550px) {
          display: inline;
        }
      }

    }

    .cta-tagline {
      font-size: 1.75rem;
      font-weight: 600;
    }

    a {
      display: inline-block;
      padding: 0.5em 2em;
      border-radius: 0.25rem;
      background: ${({ theme }) => theme.red};
      color: white;
      text-decoration: none;
      transition: background 0.25s;

      &:hover {
        background: #222;
      }
    }
  }

  .sns {
    background: #f0f0f0;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 1rem);
      max-width: 1000px;
      margin: 0 auto;
      padding: 1.5rem 0;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }
    }

    h3 {
      margin: 0;
      font-family: sans-serif;
      font-size: 2rem;

      @media (max-width: 600px) {
        margin: 0 0 0.5rem;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      font-size: 1.75rem;
    }

    li {
      margin: 0 0.5rem;

      &:last-child {
        margin: 0 0 0 0.5rem;
      }
    }

    a {
      color: black;
      text-decoration: none;
      transition: color 0.25s;

      &:hover {
        color: ${({ theme }) => theme.red};
      }
    }

    span {
      ${hideVisually()}
    }
  }

  .contact {
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% - 1rem);
      max-width: 1000px;
      margin: 1rem auto;

      > a {
        width: 25%;

        @media (max-width: 920px) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .phone,
  .email {
    width: 37.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 920px) {
      width: 50%;
    }

    @media (max-width: 700px) {
      width: 100%;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      border-radius: 3rem;
      font-size: 1.5rem;
      color: white;
    }

    h3 {
      position: relative;
      font-size: 1.5rem;

      @media (max-width: 375px) {
        font-size: 1.25rem;
      }
    }
  }

  .phone {
    @media (max-width: 920px) {
      justify-content: center;
    }
  }

  .email {
    justify-content: flex-end;

    @media (max-width: 920px) {
      justify-content: center;
    }
  }

  .phone .icon {
    background: ${({ theme }) => theme.yellow};
  }

  .email .icon {
    background: ${({ theme }) => theme.red};
  }

  .phonehours {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .tagline {
    margin: 2rem 0 1rem;
    padding: 0 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .copyright {
    padding: 0.5em 0;
    background: #494949;
    color: white;
    font-size: 0.75rem;
    text-align: center;
  }
`
