import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Navigation from "./navigation"
import { hideVisually } from "polished"
import imgLogo from "../../images/logo-horizontal.png"
import imgContact from "../../images/icon-contact.png"

const Header = () => (
  <HeaderOuter>
    <HeaderInner>
      <H1>
        <Link to="/">
          <span>TryHard DJ Academy</span>
          <img src={imgLogo} alt="TryHard DJ Academy" />
        </Link>
      </H1>
      <Description>
        <span>大阪心斎橋で学ぶ</span>
        <span>関西最大のDJ/DTMスクール</span>
      </Description>
      <Buttons>
        <TrialButton to="/lesson">無料体験レッスン</TrialButton>
        <CallButton as="a" href="tel:0662268667">
          TEL: 06-6226-8667
        </CallButton>
      </Buttons>
      <Contact to="/contact">
        <span>Contact</span>
      </Contact>
      <Navigation />
    </HeaderInner>
  </HeaderOuter>
)

export default Header

const HeaderOuter = styled.header``

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 1rem);
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem 0;
`

const H1 = styled.h1`
  order: 1;
  margin: 0;
  span {
    ${hideVisually()}
  }
`

const Description = styled.p`
  order: 2;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 900;

  span {
    display: block;
  }

  @media (max-width: 820px) {
    ${hideVisually()}
  }
`

const Buttons = styled.div`
  order: 3;

  @media (max-width: 620px) {
    width: calc(100% - 50px);
    padding: 0.5rem 0 0 0;
    order: 3;
  }

  @media (max-width: 400px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

const Button = styled(Link)`
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 2em;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 900;
  text-decoration: none;
  transition: background 0.25s;

  @media (max-width: 340px) {
    font-size: 0.75rem;
  }

  &:hover {
    background: ${({ theme }) => theme.dark};
  }
`

const TrialButton = styled(Button)`
  background: ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.light};
  margin-right: 1rem;
`

const CallButton = styled(Button)`
  background: ${({ theme }) => theme.yellow};
  color: ${({ theme }) => theme.dark};
  transition: background 0.25s, color 0.25s;

  &:hover {
    color: white;
  }
`

const Contact = styled(Link)`
  order: 4;
  display: inline-block;
  width: 47px;
  background: url(${imgContact}) no-repeat center/contain;

  &:before {
    content: "";
    display: block;
    padding-top: 80.8510638%;
  }

  span {
    ${hideVisually()}
  }

  @media (max-width: 620px) {
    order: 4;
  }

  @media (max-width: 400px) {
    ${hideVisually()}
  }
`
