// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-dj-js": () => import("./../../../src/pages/course/dj.js" /* webpackChunkName: "component---src-pages-course-dj-js" */),
  "component---src-pages-course-dtm-js": () => import("./../../../src/pages/course/dtm.js" /* webpackChunkName: "component---src-pages-course-dtm-js" */),
  "component---src-pages-course-kids-js": () => import("./../../../src/pages/course/kids.js" /* webpackChunkName: "component---src-pages-course-kids-js" */),
  "component---src-pages-course-mc-js": () => import("./../../../src/pages/course/mc.js" /* webpackChunkName: "component---src-pages-course-mc-js" */),
  "component---src-pages-course-vj-js": () => import("./../../../src/pages/course/vj.js" /* webpackChunkName: "component---src-pages-course-vj-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-js": () => import("./../../../src/pages/instructors.js" /* webpackChunkName: "component---src-pages-instructors-js" */),
  "component---src-pages-lesson-js": () => import("./../../../src/pages/lesson.js" /* webpackChunkName: "component---src-pages-lesson-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thanks-lesson-js": () => import("./../../../src/pages/thanks-lesson.js" /* webpackChunkName: "component---src-pages-thanks-lesson-js" */),
  "component---src-templates-instructor-js": () => import("./../../../src/templates/instructor.js" /* webpackChunkName: "component---src-templates-instructor-js" */),
  "component---src-templates-student-js": () => import("./../../../src/templates/student.js" /* webpackChunkName: "component---src-templates-student-js" */)
}

