import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import { Helmet } from 'react-helmet'
import "sanitize.css"
import "sanitize.css/typography.css"
import "sanitize.css/forms.css"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, path }) => {
  const [isHome, setIsHome] = useState(true)

  useEffect(() => {
    setIsHome(path === "/" ? true : false)
  }, [path])

  const removeTrailingSlash = (string) => string.replace(/\/$/, "")

  return (
    <>
      <Helmet>
        <script src="https://r.moshimo.com/af/r/maftag.js"></script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Global />
        <Site className={isHome ? "is-home" : ""}>
          <Header />
          <main>{children}</main>
          <Footer currentPath={removeTrailingSlash(path)} />
        </Site>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Site = styled.div`
  position: relative;
  overflow: hidden;
`

const Global = createGlobalStyle`
  body {
    /* font-family: vdl-v7mincho, serif; */
    font-family: "游明朝", YuMincho, serif;
    font-weight: 500;
  }

  main {
    h1 {
      margin: 0;
      color: white;
      text-align: center;
      font-size: 1.75rem;
      font-weight: 700;

      span {
        display: inline-block;
        padding: 1rem 3rem;
        border-radius: 3.75rem;
        background: black;
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      border-bottom: 2px solid black;

      .is-home & {
        text-align: center;
        border: 0;
      }
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
    }

    h4 {
      font-size: 1.125rem;
      font-weight: 700;
    }

    h5 {
      font-size: 1rem;
      font-weight: 700;
    }

    h6 {
      font-size: 1rem;
      font-weight: 500;
      text-decoration: underline;
    }
  }

`

const theme = {
  dark: "#222",
  light: "#fff",
  red: "#e60039",
  yellow: "#ffcd03",
}
