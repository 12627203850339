import React from "react"

import styled, { css } from "styled-components"

export default ({ clickHandler, state, ...props }) => (
  <Overlay onClick={clickHandler} className={`state-${state}`} {...props} />
)

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ zIndex }) => zIndex || `999`};
  background: ${({ background }) => background || `rgba(0,0,0,0.1)`};
  ${({ blur }) =>
    blur !== false &&
    css`
      -webkit-backdrop-filter: blur(1.5rem);
      backdrop-filter: blur(1.5rem);
    `}
  transition: opacity 0.5s;

  &.state-entering,
  &.state-exiting,
  &.state-exited {
    opacity: 0;
  }
  &.state-entered {
    opacity: 1;
  }
`
