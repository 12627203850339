import React, { useState } from "react"
import { Link } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import { hideVisually } from "polished"
import Overlay from "../overlay"

const CompLayoutNavigation = () => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Toggle onClick={toggleOpen} className={open ? "is-open" : ""}>
        <ToggleBars>
          <span>Menu</span>
        </ToggleBars>
      </Toggle>
      <Navigation className={open ? "is-open" : ""}>
        <ul>
          <li>
            <Link to="/" onClick={toggleOpen}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/courses" onClick={toggleOpen}>
              コース紹介
            </Link>
          </li>
          <li>
            <Link to="/instructors" onClick={toggleOpen}>
              教師紹介
            </Link>
          </li>
          <li>
            <Link to="/students" onClick={toggleOpen}>
              生徒の声
            </Link>
          </li>
          <li>
            <Link to="/lesson" onClick={toggleOpen}>
              無料体験レッスン
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleOpen}>
              お問い合わせ
            </Link>
          </li>
        </ul>
      </Navigation>
      {open && <Overlay clickHandler={toggleOpen} blur={false} />}
      {open && <FreezeBody />}
    </>
  )
}

export default CompLayoutNavigation

const Toggle = styled.button`
  position: relative;
  z-index: 1001;
  order: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 3rem;
  height: 3rem;
  padding: 0;
  border: 0;
  appearance: none;

  /* &.is-open {
    position: fixed;
    top: 0.5rem;
    right: calc((100vw - 1000px) / 2);

    @media (max-width: 1064px) {
      right: 0.5rem;
    }
  } */

  @media (max-width: 620px) {
    order: 2;
  }
`

const ToggleBars = styled.span`
  --color: ${({ theme }) => theme.red};
  .is-open & {
    --color: ${({ theme }) => theme.red};
  }

  transition: transform 0.15s, background 0.15s;

  &,
  &::before,
  &::after {
    position: relative;
    display: block;
    width: 2rem;
    height: 2px;
    /* border-radius: 0.25rem; */
    background: var(--color);
    /* transition-timing-function: linear; */
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
  }

  &::before {
    top: -0.67rem;
    transition: top 0.25s 0.25s, transform 0.15s 0s, background 0.15s;
  }

  &::after {
    bottom: -0.67rem;
    transition: bottom 0.25s 0.25s, opacity 0.15s 0s, background 0.15s;
  }

  span {
    ${hideVisually()}
  }

  .is-open & {
    transform: rotate(45deg);
    transition: transform 0.15s 0.25s, background 0.15s;

    &::before {
      top: 0;
      transform: rotate(90deg);
      transition: top 0.25s 0s, transform 0.15s 0.3s, background 0.15s;
    }

    &::after {
      bottom: 0;
      opacity: 0;
      transition: bottom 0.25s 0s, opacity 0.25s 0.25s, background 0.15s;
    }
  }
`

const Navigation = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  /* width: 33vw; */
  min-width: 300px;
  height: 100vh;
  margin: 0 0 1rem;
  padding: 5rem calc((100vw - 1000px) / 2) 2rem 5rem;
  background: white;
  text-align: right;
  font-size: 1.5rem;
  border-left: 0.5rem solid ${({ theme }) => theme.yellow};
  transform: translateX(100%);
  transition: transform 0.25s;

  @media (max-width: 1064px) {
    padding: 5rem 2rem 2rem 2rem;
    text-align: left;
  }

  &.is-open {
    transform: translateX(0%);
  }

  ul {
  }

  li {
  }

  a {
    --color: ${({ theme }) => theme.red};
    display: inline-block;
    text-decoration: none;
    color: #222;
    background: linear-gradient(to top, red 0, red 100%);
    background-repeat: no-repeat;
    background-size: 0% 2px;
    background-position: 100% 100%;
    transition: background-size 0.25s;

    &:hover {
      background-size: 100% 2px;
    }
  }
`

const FreezeBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
